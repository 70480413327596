<template>
    <div>
        <el-dialog class="cus_dialog " title="保险统计" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="80%" height="90%">

            <div style="text-align:right">
                <el-button icon="el-icon-download" @click="exportXls" style="margin-left:10px"
                    size="small">导出详情表格</el-button>
            </div>

            <div id="chart" style="height:500px"></div>
        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["baoxian"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            DataList: []
        }
    },
    mounted() {

        this.getList()
    },
    methods: {
        getList() {

            this.$http.post("/api/baoxian_tongji", this.baoxian_tongji).then(res => {
                let schDic = {}
                for (let item of res.data.tongji) {
                    if (!schDic[item.pmid]) {
                        schDic[item.pmid] = item
                    } else {
                        schDic[item.pmid].n += item.n
                        if (item.buyok === 1) {
                            schDic[item.pmid].buyok = item.n
                        }
                        if (item.buyok === 0) {
                            schDic[item.pmid].buyno = item.n
                        }
                    }
                }
                let DataList = []
                for (let k in schDic) {
                    DataList.push(schDic[k])
                }
                this.DataList = DataList
                this.initChart()
            })
        },
        closeDialog() {

            this.$$parent(this, "dialogShow", false)

        },
        initChart() {

            let xdata = this.DataList;

            let option = {
                xAxis: {
                    type: 'category',
                    data: xdata.map(e => { return e.platform }),
                    axisLabel: {
                        rotate: 30 // 设置标签旋转角度为 45 度
                    }
                },
                grid: {
                    bottom: 100,
                },
                yAxis: {
                    type: 'value',
                    name:"购买率",
                    axisLabel: {
                        formatter: '{value}%'
                    }
                },
                tooltip: {
                    formatter: function (params) {

                        const value = params.value;
                        return '保险购买率：' + value + "%<br />总人数：" + xdata[params.seriesIndex].n +
                            "<br />保险未购买人数：" + xdata[params.seriesIndex].buyno;
                    }
                },
                series: [
                    {
                        data: xdata.map(e => {
                            let value = parseInt(e.buyok / e.n * 10000) / 100;
                            let data = { value: value, itemStyle: { color: '#409eff' } }
                            return data
                        }),
                        type: 'bar',
                        barWidth: 30,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            };
            var yjChart = this.$echarts.init(document.getElementById('chart'));
            yjChart.setOption(option);

        },
        exportXls() {
            this.$http.post("/api/baoxian_xls_tj", this.baoxian).then(res => {
                if (res.data.url) {
                    window.open(res.data.url, "_blank")
                    this.$message({
                        type: 'success',
                        message: '导出成功!'
                    });
                }
            })
        }



    }

}

</script>

<style scoped></style>