<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right">


				<el-select v-model="searchForm.school_id" clearable placeholder="全部学校" size="small"
					style="width:200px;margin-left:10px">
					<el-option label="全部学校" value></el-option>
					<el-option :label="item.platform" v-for="(item, idx) in SchoolList" :key="idx"
						:value="item.id"></el-option>

				</el-select>

				<el-select size="small" style="margin-left:10px;width:120px" v-model="searchForm.status" clearable
					placeholder="保险状态">
					<el-option label="已购保险" :value="1"></el-option>
					<el-option label="未购保险" :value="0"></el-option>
				</el-select>

				<!-- <el-select size="small" style="margin-left:10px;width:150px"  v-model="searchForm.bxcompany" filterable clearable placeholder="保险公司">
					
				</el-select> -->



				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:150px;margin-left: 10px" clearable></el-input>


				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>

				<el-button icon="el-icon-data-analysis" @click="dialogShow=true" style="margin-left:10px" size="small"
					type="primary">统计表</el-button>
				<el-button icon="el-icon-download" @click="exportXls" style="margin-left:10px"
					size="small">导出详情表格</el-button>



			</div>
			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
                    <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
                        <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
                    </div>
                    <div style="margin-left: 50px; color:#535353; font-size:20px !important">保险列表</div>
                </div>
				
			</div>
		</div>
		<div class="bcontent">

			<div style="position: relative;min-height: 26px;">

				<el-collapse-transition>
					<div v-show="showTj">


						<div>
							<el-tag effect="plain">
								已购买实习险人数：
							</el-tag>
							<el-tag effect="plain" type="success" v-if="item.buyok == 1" style="margin-left:10px"
								v-for="(item, idx) in tongji" :key="idx">
								{{ item.platform }}:{{ item.n }}人
							</el-tag>


						</div>
						<div style="padding:10px 0">
							<el-tag effect="plain">
								未购买实习险人数：
							</el-tag>
							<el-tag effect="plain" type="success" v-if="item.buyok == 0" style="margin-left:10px"
								v-for="(item, idx) in tongji" :key="idx">
								{{ item.platform }}:{{ item.n }}人
							</el-tag>


						</div>




					</div>
				</el-collapse-transition>
				<div style="position: absolute; right:0;bottom: 0; color: darkgray;font-size: 20px;">
					<i :class="[showTj ? 'el-icon-document-remove' : 'el-icon-document-add']" @click="showTj = !showTj"
						style="cursor: pointer;" :title="showTj ? '折叠' : '展开'"></i>
				</div>
			</div>

			<div>
				<el-table :data="tableData" row-key="id" border stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column>
					<el-table-column label="所属系部" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
					<el-table-column label="学生姓名" prop="stu_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="学号" prop="stu_code" width="150" align="center"></el-table-column>
					<el-table-column label="保险险种名称" prop="bx_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="保单号" prop="bx_bdh" width="150" align="center"></el-table-column>
					<el-table-column label="保费金额" prop="bx_money" width="150" align="center"></el-table-column>
					<el-table-column label="保险出资方" prop="bx_czf" min-width="150" align="center"></el-table-column>

					<!-- <el-table-column  fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column> -->
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>
		<bxtongji v-if="dialogShow" :baoxian="searchForm"></bxtongji>


		<el-dialog class="cus_dialog " title="保单导入" :visible.sync="dialogshow1" :close-on-click-modal="false" width="600px"
			height="30%">
			<div style="line-height: 50px;display: inline-block;">
				<a href="/xls/实习责任险导入模板.xlsx" target="_blank">实习责任险导入模板下载</a>
			</div>
			<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
				:on-success="uploadFiles" :show-file-list="false" name="image">
				<el-button icon="el-icon-upload" type="primary" style="margin-left:100px" size="small">导入实习责任险</el-button>
			</el-upload>

		</el-dialog>

	</div>
</template>

<script>
import bxtongji from './com/bxtongji.vue'

export default {
	components: {
		bxtongji


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			showTj: true,
			SchoolList: [],
			searchForm: {
				school_id: "",
				class_name: "",
				keyword: "",
				status: "",
				bxcompany: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			baoxian: null,
			tongji: []
		}
	},
	mounted() {
		this.getSchoolList()
		this.getDataList()
		//this.getTree()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				this.SchoolList = res.data
			})
		},
		getTargetPmid() {
			this.$http.post("/api/sch_qsx_target").then(res => {
				if (res.data && res.data.qsx_pmid) {
					this.TargetPmid = res.data.qsx_pmid
				}
			})

		},
		getTree() {
			this.$http.post("/api/baoxian_grade_tree").then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_baoxian", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				school_id: this.searchForm.school_id,
				showloading: true
			}
			this.$http.post("/api/baoxian_tongji", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
				this.tongji = res.data.tongji
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.baoxian = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			this.$http.post("/api/import_baoxianxls", {
				url: e.src
			}).then(res => {

				this.getDataList()
				this.$message.success("导入成功")
				if (res.data.msg.length > 0) {
					let html = ''
					for (let msg of res.data.msg) {
						html += "<div style='font-size:12px'>" + msg.stu_name + ":" + msg.msg + "</div>"
					}
					this.$alert(html, '未导入数据', {
						dangerouslyUseHTMLString: true
					});
				}
				this.dialogshow1 = false


			})
		},
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/baoxian_delete", { id: e.id }).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});




		},
		exportXls() {
			let data = {

				keyword: this.searchForm.keyword,
				status: this.searchForm.status,
				school_id: this.searchForm.school_id,
				showloading: true
			}
			this.$http.post("/api/baoxian_xls", data).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
					this.$message({
						type: 'success',
						message: '导出成功!'
					});
				}
			})
		}

	}
}
</script>
<style scoped></style>
